.horizontal-bar-rating {
  display: flex;
  align-items: center;
}

.stars {
  display: flex;
  margin-right: 10px;
}

.star {
  font-size: 16px;
  color: #ffd700;
  margin-right: 5px;
}

.star.filled {
  color: #ffac00; /* You can change this color for filled stars */
}

.bar {
  width: 100%;
  height: 6px;
  background-color: #edf2f7; /* Bar background color */
  position: relative;
}

.fill {
  height: 100%;
  background-color: #0157ff; /* Fill color */
}
