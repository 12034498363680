@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-bg {
  background-image: url('./assets/footer-bg.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* height: 10vh; */
}

.transparent-bg {
  background-color: rgba(0, 0, 0, 0.1);
}

.checkout-bg {
  background-image: url('./assets/checkout-bg.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-blend-mode: color;
}

.bg-btcText {
  background-image: url('./assets/btc-text-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-btcCoin {
  background-image: url('./assets/btc-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-login {
  background-image: url('./assets/signin-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-signup {
  background-image: url('./assets/sign-up.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* #react-select-3-input {
  display: none;
} */

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input {
  -moz-appearance: textfield;
}

.dashboard-net-shadow {
  box-shadow:
    0px 6px 18px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.card1-shadow {
  box-shadow: 0px 8px 24px 0px rgba(21, 101, 192, 0.16);
}

.card2-shadow {
  box-shadow: 0px 8px 24px 0px rgba(105, 150, 53, 0.16);
}

.card3-shadow {
  box-shadow: 0px 8px 24px 0px rgba(86, 21, 222, 0.16);
}

.card4-shadow {
  box-shadow: 0px 8px 24px 0px rgba(203, 12, 207, 0.16);
}

.portfolio-table canvas {
  height: 50px !important;
}

input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
}

.country__input {
  @apply focus:ring-0 focus:ring-offset-0;
}

.phone-input input {
  border-radius: 0.375rem;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 640px) {
  .marketshare canvas {
    /* width: 600px !important; */
    height: 150px;
    width: 550px;
  }
}

@media (min-width: 1024px) {
  .marketshare canvas {
    /* width: 600px !important; */
    height: 150px;
    width: 500px;
  }

  .dashboard canvas {
    min-height: 300px;
  }
}

.bar-fill {
  height: 100%;
  background-color: #00d467;
}

@layer utilities {
  .shadow-all-sides {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.categories {
  list-style: num !important;
}

.categories li {
  margin-left: 24px;
}

.main-logo {
  height: 54px;
}

.error-icon {
  fill: red;
  /* margin-top: -10px; */
}

#table-row td:first-child .w-28 {
  width: 10px;
  margin-right: 15px;
}

.pagination li a {
  /* border-radius: 7px; */
  padding: 0.1rem 1rem;
  font-weight: 600;
  /* border: gray 1px solid;
  cursor: pointer; */
}
.pagination li.previous a,
.pagination li.next a,
.pagination li.break a {
  border-color: transparent;
}
.pagination li.active a {
  /* background-color: #0366d6;
  border-color: transparent; */
  color: #0366d6;
  min-width: 32px;
}
.pagination li.disabled a {
  color: grey;
}
.pagination li.disable,
.pagination li.disabled a {
  cursor: default;
}
